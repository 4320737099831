import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import leadEstate from "./leadEstate.png";
import { useNavigate } from "react-router-dom";
import "./VisitorForm.css";

const VisitorForm = () => {
  const navigate = useNavigate();
  const { userId, openhouseId } = useParams();

  const [formStage, setFormStage] = useState(0);
  const [formData, setFormData] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const progressSteps = ["Step 1", "Step 2", "Step 3"];

  useEffect(() => {
    setFormStage(0);
  }, []);

  const apiUrl = `${process.env.REACT_APP_BASE_URL}/visitors/create/`;

  async function postVisitorData(data) {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  const onSubmit = (data) => {
    let updatedData;
    if (formStage === 0) {
      updatedData = { ...formData, ...data };
      setFormData(updatedData);
      setFormStage(1);
    } else if (formStage === 1) {
      updatedData = { ...formData, ...data };
      setFormData(updatedData);
      setFormStage(2);
    } else {
      updatedData = { ...formData, ...data, open_house: openhouseId };
      setFormData(updatedData);
      console.log(updatedData);
      postVisitorData(updatedData)
        .then((data) => {
          console.log(data);
          navigate("/end");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <div>
      <div className="progress-bar">
        {progressSteps.map((step, index) => (
          // progress bar conditional render for steps in form
          <div
            key={index}
            className={`progress-bar-step ${
              index <= formStage ? "active" : ""
            }`}
          ></div>
        ))}
      </div>
      <div className="form-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          {formStage === 0 && (
            <>
              <h2>Welcome!</h2>
              <label> Full Name</label>
              <input
                className="my-input"
                {...register("name", { required: true })}
                // capitalizes the first letter of each string
                onInput={(event) => {
                  event.target.value = event.target.value
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ");
                }}
              />
              {errors.name && <p>This field is required</p>}
              <label>Phone</label>
              <input
                style={{ width: "70%" }}
                className="my-input"
                {...register("phone", { pattern: /^[0-9]*$/ })}
                placeholder="123-456-7890"
                maxLength="10"
              />
              {errors.phone && <p>This field should be a valid phone number</p>}
              <label>Email</label>
              <input
                className="my-input"
                {...register("email", {
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors.email && <p>This field should be a valid email</p>}
              <button type="submit">Next</button>
            </>
          )}
          {formStage === 1 && (
            <>
              <h2>Almost There!</h2>
              <label>Are you currently working with a real estate agent?</label>
              <label>
                <input
                  type="radio"
                  value="Yes"
                  {...register("working_with_agent", { required: true })}
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  value="No"
                  {...register("working_with_agent")}
                />
                No
              </label>
              {errors.question1 && <p>This field is required</p>}
              <button type="submit">Next</button>
            </>
          )}
          {formStage === 2 && (
            <>
              <div className="radio container">
                <h2>Last One</h2>

                <label>When do you plan on buying a house?</label>
                <label>
                  <input
                    type="radio"
                    value="Looking to buy now"
                    {...register("buying_timeline", { required: true })}
                  />
                  Looking to buy now
                </label>
                <label>
                  <input
                    type="radio"
                    value="Just browsing"
                    {...register("buying_timeline", { required: true })}
                  />
                  Just browsing
                </label>
                <label>
                  <input
                    type="radio"
                    value="Plan to buy within a year"
                    {...register("buying_timeline", { required: true })}
                  />
                  Plan to buy within a year
                </label>
                <label>
                  <input
                    type="radio"
                    value="Interested in selling"
                    {...register("buying_timeline", { required: true })}
                  />
                  Interested in selling
                </label>
                {errors.question2 && <p>This field is required</p>}
                <div className="submit-button-container">
                  <button type="submit">Submit</button>
                </div>
              </div>
            </>
          )}
        </form>
        {/* <div className="info-container"> */}
        <span style={{ paddingTop: "20px" }}>Powered by </span>
        <img className="app-logo" src={leadEstate} alt="app logo" />
        {/* </div> */}
      </div>
    </div>
  );
};

export default VisitorForm;
