import React, { useEffect, useState } from "react";
import "./LoadingPage.css";

const LoadingPage = () => {
  const [loadingMessage, setLoadingMessage] = useState(
    "Fetching open house details..."
  );

  useEffect(() => {
    // obtain realtor id for open house tracking
    const realtorId = new URLSearchParams(window.location.search).get("realtor_id");
    
    // if id exists, find visitor's timezone to help find the right open house
    if (realtorId) {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log(timezone, 'timezone');
        
        fetch(`${process.env.REACT_APP_BASE_URL}/user/get_open_house/?realtor_id=${realtorId}&timezone=${timezone}`)
            .then(response => {
                console.log('Raw response:', response);
                return response.json();
            })
            .then(text => {
                console.log('Raw text:', text);
                try {
                    // const data = JSON.parse(text);
                    const data = text;
                    if (data.success && data.url) {
                        window.location.href = data.url;
                    } else {
                        setLoadingMessage(data.message || "No ongoing open houses.");
                    }
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            })
            .catch(error => {
                console.error("Error fetching open house:", error);
                // setLoadingMessage("An error occurred. Please try again later.");
                window.location.href = "https://leadestateapp.com/no-open-houses/";
            });
    } else {
        setLoadingMessage("Invalid request. Missing realtor_id.");
    }
}, []);


  return (
    <div className="loading-container">
      <div className="spinner"></div>
      <div className="text-container">
        <h2 style={{ color: "white", fontSize: "30px" }}>Hang Tight!</h2>
        <p style={{ color: "white" }}>{loadingMessage}</p>
      </div>
    </div>
  );
};

export default LoadingPage;
