import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import VisitorForm from './VisitorForm';
import ThankYou from './ThankYou';
import LoadingPage from './LoadingPage';
import NoOpenHouses from './NoOpenHouses';

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/loading-page/" element={<LoadingPage />} />
      <Route path="/user/:userId/openhouse/:openhouseId" element={<VisitorForm />} />
      <Route path="/no-open-houses/" element={<NoOpenHouses />} />
      <Route path="/end" element={<ThankYou/>} />
      {/* <Route path="/" element={<VisitorForm />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
