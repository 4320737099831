import React from "react";
import "./ThankYou.css";
import leadEstate from "./leadEstate.png";

export default function ThankYou() {
  return (
    <div className="end-container">
      <h2 className="white-text">Thank You!</h2>
      <span style={{ color: "white" }} className="padding-top">
        Powered by{" "}
      </span>
      <img className="app-logo" src={leadEstate} alt="app logo" />
    </div>
  );
}
