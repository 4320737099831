import React from "react";
import leadEstate from "./leadEstate.png";
import "./NoOpenHouses.css";

export default function NoOpenHouses() {
  return (
    <div className="no-open-container" style={{ color: "white" }}>
      <h2 style={{ color: "white" }}>
        Sorry, no open houses available right now
      </h2>
      <span style={{ paddingTop: "20px" }}>Powered by </span>
      <img className="app-logo" src={leadEstate} alt="app logo" />
    </div>
  );
}
